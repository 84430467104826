import { Alert, AlertTitle, Box, Container, Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { db, doc, getDoc } from "./utils/firebase.js";

const ErrorMessage = (props) => {
  return (
    <Alert severity="error">
      <AlertTitle>Error</AlertTitle>
      {props.error}
    </Alert>
  );
};

export default function Redirect() {
  const { urlCode } = useParams();
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    var docRef = doc(db, "urls", urlCode);

    getDoc(docRef)
      .then((doc) => {
        if (doc.exists()) {
          window.location.href = doc.data().url;
        } else {
          setError("Code not found");
        }
      })
      .catch((error) => {
        setError(
          "Error getting document: " + error.code + ". Please try again."
        );
      });
  }, [urlCode]);

  return (
    <Container component="main" maxWidth="lg" minWidth="md">
      <Box
        sx={{
          marginTop: 3,
          marginBottom: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {error ? (
          <ErrorMessage error={error} />
        ) : (
          <Typography variant="h4">Redirecting...</Typography>
        )}
      </Box>
    </Container>
  );
}
