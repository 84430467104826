import { CopyAll, Delete } from "@mui/icons-material";
import LinkIcon from "@mui/icons-material/Link";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Box,
  Button,
  Container,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "./AuthProvider";
import Login from "./Login";
import {
  collection,
  db,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "./utils/firebase.js";

const ErrorMessage = (props) => {
  return (
    <Alert severity={props.error[0]}>
      <AlertTitle>{props.error[0]}</AlertTitle>
      {props.error[1]}
    </Alert>
  );
};

function Home() {
  const { currentUser } = useContext(AuthContext);
  const [urls, setUrls] = useState([]);
  const [status, setStatus] = useState([null, null]);

  //keep track of if the urls collection has been loaded so it is not loaded more than once
  const [urlsLoaded, setUrlsLoaded] = useState(false);

  useEffect(() => {
    //load urls from firebase
    if (currentUser !== null && !urlsLoaded) {
      getDocs(
        query(collection(db, "urls"), where("uid", "==", currentUser.uid))
      ).then((docs) => {
        docs.forEach((doc) => {
          setUrls((prevUrls) => [
            ...prevUrls,
            { url: doc.data().url, code: doc.id },
          ]);
        });
      });
      setUrlsLoaded(true);
    }
  }, [currentUser, urlsLoaded]);

  useEffect(() => {
    if (!currentUser) {
      setUrls([]);
      setUrlsLoaded(false);
    }
  }, [currentUser]);

  //add a new url to the urls collection when the form is submitted
  function addUrl() {
    const url = document.getElementById("url").value;

    //validate the url
    if (url.length === 0) {
      setStatus(["error", "Please enter a URL"]);
      return;
    }
    const URLregex =
      /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/; //stolen from https://urlregex.com
    if (!URLregex.test(url)) {
      setStatus(["error", "Please enter a valid URL"]);
      return;
    }

    //validate the code
    let code = document.getElementById("code")?.value;

    let codeProvided = true;
    if (code === "" || code === undefined) {
      code = "";
      const chars =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-_";
      for (let i = 0; i < 11; i++) {
        code += chars[Math.floor(Math.random() * 64)];
      }
      codeProvided = false;
    } else {
      const codeRegex = /^[a-z0-9A-Z\-_]+$/;
      if (!codeRegex.test(code)) {
        setStatus(["error", "Please enter a valid code"]);
        return;
      }
    }

    //while the code is already in use, get a new one
    getDoc(doc(db, "urls", code)).then((document) => {
      if (document.exists()) {
        //if the code was provided, say it's already in use
        if (codeProvided) {
          setStatus(["error", "Code already in use"]);
          return;
        }
        addUrl(url);
      } else {
        //add the url to the urls collection
        setDoc(doc(db, "urls", code), { url: url, uid: currentUser.uid })
          .then(() => {
            setStatus(["success", "Code added!"]);
            //add the url to the urls array
            setUrls((prevUrls) => [...prevUrls, { url: url, code }]);
          })
          .catch((error) => {
            setStatus(["error", error.code]);
          });
      }
    });
  }

  function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand("copy");
      if (successful) {
        setStatus(["success", "Code copied!"]);
      } else {
        setStatus(["error", "Code could not be copied"]);
      }
    } catch (err) {
      setStatus(["error", "Unable to copy"]);
    }

    document.body.removeChild(textArea);
  }
  function copyTextToClipboard(text) {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(
      function () {
        setStatus(["success", "Copied to clipboard!"]);
      },
      function (err) {
        setStatus(["error", "Unable to copy"]);
      }
    );
  }

  return (
    <Container component="main" maxWidth="md">
      <Box
        sx={{
          marginTop: 3,
          marginBottom: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          component="h1"
          variant="h3"
          sx={{
            textAlign: "center",
          }}
        >
          <LinkIcon
            sx={{ marginRight: "1rem" }}
            fontSize="large"
            color="primary"
          />
          URL Shortener
        </Typography>
        {currentUser ? (
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Box
              sx={{
                marginTop: 8,
                marginBottom: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography component="h2" variant="h4" textAlign="center">
                Add a URL
              </Typography>
              <TextField
                id="url"
                label="URL"
                variant="outlined"
                margin="normal"
                fullWidth
                required
              />
              {/* I don't really care that much if people bypass this*/}
              {[
                "LgvgZ7xSw4QxK3IHYkYlmd6IepD2",
                "zOBzdHodC2V34wvIXbcFYluhCYI3",
              ].includes(currentUser.uid) && (
                <TextField
                  id="code"
                  label="Code (optional)"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                />
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={addUrl}
                fullWidth
              >
                Add
              </Button>
            </Box>
            {status[0] && <ErrorMessage error={status} />}
            {!!urls.length && (
              <Box
                sx={{
                  marginTop: 8,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Accordion
                  defaultExpanded={true}
                  sx={{
                    maxWidth: "md",
                  }}
                >
                  <AccordionSummary>
                    <Typography
                      component="h2"
                      variant="h4"
                      width="100%"
                      textAlign="center"
                    >
                      My Shortened URLs
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Shortened URL</TableCell>
                          <TableCell></TableCell>
                          <TableCell>Redirects to</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {urls.map((url) => (
                          <TableRow key={url.code}>
                            <TableCell
                              sx={{
                                wordBreak: "no-break",
                                paddingRight: "0",
                              }}
                            >
                              <Link href={"https://go.alexbr.dev/" + url.code}>
                                {"https://go.alexbr.dev/" + url.code}
                              </Link>
                            </TableCell>
                            <TableCell
                              sx={{
                                wordBreak: "no-break",
                                paddingLeft: "0",
                              }}
                            >
                              <IconButton
                                aria-label="copy"
                                onClick={() => {
                                  copyTextToClipboard(
                                    "https://go.alexbr.dev/" + url.code
                                  );
                                }}
                              >
                                <CopyAll />
                              </IconButton>
                            </TableCell>
                            <TableCell
                              sx={{
                                wordBreak: "break-all",
                              }}
                            >
                              <Link href={url.url}>{url.url}</Link>
                            </TableCell>
                            <TableCell>
                              <IconButton
                                aria-label="delete"
                                onClick={() => {
                                  deleteDoc(doc(db, "urls", url.code))
                                    .catch((error) => {
                                      setStatus(["error", error.code]);
                                    })
                                    .then(() => {
                                      setStatus(["success", "URL deleted!"]);
                                      setUrls((prevUrls) =>
                                        prevUrls.filter(
                                          (u) => u.code !== url.code
                                        )
                                      );
                                    });
                                }}
                              >
                                <Delete />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </AccordionDetails>
                </Accordion>
              </Box>
            )}
          </Box>
        ) : (
          <Login></Login>
        )}
      </Box>
    </Container>
  );
}

export default Home;
