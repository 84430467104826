import { initializeApp } from "firebase/app";
import {
  createUserWithEmailAndPassword, getAuth, onAuthStateChanged, sendPasswordResetEmail, signInWithEmailAndPassword
} from "firebase/auth";
import {
  collection, deleteDoc, doc,
  getDoc, getDocs, getFirestore, query, setDoc, where
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBUFaK2vkJWO45KAeNb8mxfa0gxScO3lzs",
  authDomain: "url-shortener-feaf5.firebaseapp.com",
  projectId: "url-shortener-feaf5",
  storageBucket: "url-shortener-feaf5.appspot.com",
  messagingSenderId: "633301077110",
  appId: "1:633301077110:web:8a4117745201f12eb02357",
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const auth = getAuth();
export {
  doc,
  getDoc,
  collection,
  getDocs,
  setDoc,
  deleteDoc,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  query,
  where,
  onAuthStateChanged,
};
