import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography
} from "@mui/material";
import { useState } from "react";
import {
  auth,
  createUserWithEmailAndPassword, sendPasswordResetEmail, signInWithEmailAndPassword
} from "./utils/firebase.js";

const ErrorMessage = (props) => {
  return (
    <Alert severity={props.status[0]}>
      <AlertTitle>{props.status[0]}</AlertTitle>
      {props.status[1]}
    </Alert>
  );
};

const Login = () => {
  const [status, setStatus] = useState([null, null]);

  const [signingIn, setSigningIn] = useState(true);

  const [resettingPassword, setResettingPassword] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    if (resettingPassword) {
      sendPasswordResetEmail(auth, data.get("email"));

      setStatus(["success", "Password reset email sent."]);
      setResettingPassword(false);
      return;
    }

    if (data.get("email") === "" || data.get("password") === "") {
      setStatus(["error", "Please fill in all fields"]);
      return;
    }

    if (signingIn) {
      signInWithEmailAndPassword(
        auth,
        data.get("email"),
        data.get("password")
      ).catch((error) => {
        setStatus(["error", error.code]);
      });
    } else {
      createUserWithEmailAndPassword(
        auth,
        data.get("email"),
        data.get("password")
      )
        .then(() => {
          setStatus(["success", "Account created."]);
        })
        .catch((error) => {
          setStatus(["error", error.code]);
        });
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          marginBottom: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h2" variant="h5">
          {resettingPassword
            ? "Reset password"
            : signingIn
            ? "Sign in"
            : "Sign up"}
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            type="email"
            name="email"
            autoComplete="email"
            autoFocus
          />
          {!resettingPassword && (
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {resettingPassword
              ? "Reset password"
              : signingIn
              ? "Sign in"
              : "Sign up"}
          </Button>
          <Grid
            container
            justifyContent={
              signingIn && !resettingPassword ? "space-between" : "center"
            }
          >
            {signingIn && (
              <Grid item>
                <Link
                  href="/"
                  variant="body2"
                  onClick={(e) => {
                    e.preventDefault();
                    setResettingPassword(!resettingPassword);
                  }}
                >
                  {resettingPassword ? "Go back to login" : "Forgot password?"}
                </Link>
              </Grid>
            )}
            {!resettingPassword && (
              <Grid item>
                <Link
                  href="/"
                  variant="body2"
                  onClick={(e) => {
                    e.preventDefault();
                    setSigningIn(!signingIn);
                  }}
                >
                  {signingIn
                    ? "Don't have an account? Sign Up"
                    : "Already have an account? Sign In"}
                </Link>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
      {status[0] && <ErrorMessage status={status} />}
    </Container>
  );
};

export default Login;
